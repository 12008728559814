import PropTypes from "prop-types"
import React from "react"

import Button from "./Button";
import ButtonGroup from './ButtonGroup';
import TheaterVideo from "../Theater/TheaterVideo";

export default function ButtonGroupMap(props) {

    if (!props.buttons){
        console.warn(`ButtonGroupMap received no buttons. Props are as follows: ${JSON.stringify(props)}`);
        return (null);
    } 

    let allButtonsComplete = true;

    for (let i = 0; i < props.buttons.length; i++) {
        let button = props.buttons[i].button;
        if (button.buttonText === "" || button.href === "") {
            allButtonsComplete = false;
            break;
        }
    }

    if (!allButtonsComplete) {
        console.warn("Button group has an incomplete button, so the button group was not rendered.")
        return (null);
    }

    return (
        <ButtonGroup {...props}>
            {
                props.buttons && props.buttons.map((buttonObj, i) => {
                    let button = buttonObj.button;
                    if (button.appearance === "video") {
                        return (
                            <TheaterVideo
                                videoUrl={`https://www.youtube.com/watch?v=${button.youtube}`}
                                controls={true}
                                playing={true}
                                onlyButton={true}
                                language={"en"}
                                buttonClass="contained"
                            />
                        )
                    } else {
                        return (
                            <Button
                                contained={button.appearance === "contained"}
                                minimal={button.appearance === "minimal"}
                                custom={button.appearance === "custom"}
                                external={button.destination === "external"}
                                anchor={button.destination === "anchor"}
                                samePageAnchor={button.destination === "samePageAnchor"}
                                tel={button.destination === "tel"}
                                key={i}
                                buttonText={button.buttonText}
                                href={button.href}
                                className={props.buttonClasses}
                            />
                        )
                    }

                })
            }
        </ButtonGroup>
    )
}

ButtonGroupMap.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            button: PropTypes.shape({
                buttonText: PropTypes.string,
                href: PropTypes.string,
                youtube: PropTypes.string,
                appearance: PropTypes.string.isRequired,
                destination: PropTypes.string
            })
        })
    )
}