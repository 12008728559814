import PropTypes from "prop-types"
import React from "react"

var classNames = require("classnames")

class ButtonGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    var buttonGroupWrapperClass = classNames("button-group-wrapper", {
      "align-left-mobile": this.props.alignLeftMobile,
    })
    var buttonGroupClass = classNames(this.props.className, {
      "button-group": true,
      "is-centered": this.props.isCentered,
      "is-centered-mobile": this.props.isCenteredMobile,
      "align-right": this.props.alignRight,
      "align-left": this.props.alignLeft,
      "align-left-mobile": this.props.alignLeftMobile,
      "row": this.props.row,
      "reverse-buttons-mobile": this.props.reverse,
      "stack-mobile": this.props.stackMobile
    })
    return (
      <div className={buttonGroupWrapperClass}>
        <div
          className={
            buttonGroupClass + ` has-${React.Children.count(this.props.children)}-button`
          }
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

ButtonGroup.propTypes = {
  isCentered: PropTypes.bool,
  alignLeft: PropTypes.bool,
  alignRight: PropTypes.bool,
  children: PropTypes.node
}

export default ButtonGroup
